import { Box, Paper, Typography } from '@mui/material';
import digitalVector from '../../assets/backgrounds/digital-vector.jpg';
import { useTranslation } from 'react-i18next';

export const Introduction = () => {
  const { t } = useTranslation();
  return (
    <Box
      id="introduction-ref"
      sx={{
        paddingTop: '20vh',
        height: '1000px',
        zIndex: 1,
        '@media (max-width: 600px)': {
          height: '480px',
          paddingTop: '20vh',
        },
      }}
    >
      <Paper
        sx={{
          background: 'linear-gradient(to right, #0c66f5, #8db4f2)100%',
          backgroundImage: `url(${digitalVector})`,
          backgroundSize: '100%',
          height: '768px',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          backgroundBlendMode: 'multiply',
          backgroundColor: '#0340a1',
          '@media (max-width: 600px)': {
            height: '368px',
            backgroundSize: '140%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            minWidth: '90%',
          }}
        >
          <Typography
            variant="h1"
            color="secondary"
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '40px',
              },
            }}
          >
            {t('it_solutions')}
          </Typography>
          <Typography
            variant="h4"
            color="secondary"
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '26px',
              },
            }}
          >
            {t('speach')}
          </Typography>
        </Box>
        {/* <Box
          sx={{
            borderRadius: '5px',
            position: 'absolute',
            top: '25%',
            left: '25%',
            width: '80%',
            height: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottomColor: '#8db4f2',
            '@media (max-width: 600px)': {
              height: '80%',
              top: '10%',
            },
          }}
        >
          
        </Box> */}
      </Paper>
    </Box>
  );
};
