import { useState } from "react";
import NavBarView from "./NavBar.view";

export const NavBarController = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOpenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return <NavBarView handleOpenMenu={handleOpenMenu} isMenuOpen={isMenuOpen} />;
};
