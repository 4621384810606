import { Box, Card, Typography } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@mui/lab';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import { useTranslation } from 'react-i18next';
import { Lister, ListerI } from '../lister/Lister';

export const HowWeWorkSteps = () => {
  const { t } = useTranslation();
  const steps = [
    {
      title: t('how_we_work.understand.title'),
      description: [
        { content: t('how_we_work.understand.item_1') },
        { content: t('how_we_work.understand.item_2') },
        { content: t('how_we_work.understand.item_3') },
      ],
      icon: <LightbulbOutlinedIcon fontSize="large" color={'primary'} />,
      disposition: 'right',
    },
    {
      title: t('how_we_work.plan.title'),
      description: [
        { content: t('how_we_work.plan.item_1') },
        { content: t('how_we_work.plan.item_2') },
        { content: t('how_we_work.plan.item_3') },
      ],
      icon: <PaletteOutlinedIcon fontSize="large" color={'primary'} />,
      disposition: 'left',
    },
    {
      title: t('how_we_work.develop.title'),
      description: [
        { content: t('how_we_work.develop.item_1') },
        { content: t('how_we_work.develop.item_2') },
        { content: t('how_we_work.develop.item_3') },
      ],
      icon: <CodeOutlinedIcon fontSize="large" color={'primary'} />,
      disposition: 'right',
    },
    {
      title: t('how_we_work.implement.title'),
      description: [
        { content: t('how_we_work.implement.item_1') },
        { content: t('how_we_work.implement.item_2') },
        { content: t('how_we_work.implement.item_3') },
      ],
      icon: (
        <InsertChartOutlinedTwoToneIcon fontSize="large" color={'primary'} />
      ),
      disposition: 'left',
    },
  ];

  const renderWebSteps = (steps: ListerI[]) => {
    return (
      <Timeline position="alternate">
        {steps.map((step) => (
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              //@ts-ignore
              align={step.disposition}
              variant="body2"
              color="primary"
            >
              <Typography variant="h4">{step.title}</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color="secondary">{step.icon}</TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Card>
                <Box
                  component={'ul'}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 4,
                  }}
                >
                  {(
                    step.description as {
                      item?: string;
                      content: string;
                    }[]
                  ).map((item) => (
                    <Typography
                      component="li"
                      sx={{
                        textAlign: 'left',
                      }}
                    >
                      {item.content}
                    </Typography>
                  ))}
                </Box>
              </Card>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  };
  return (
    <Box
      sx={{
        margin: '0vh 0 0vh 0',
        '@media (max-width: 600px)': {
          margin: '0',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          '@media (max-width: 600px)': {
            display: 'none',
          },
        }}
      >
        {renderWebSteps(steps)}
      </Box>
      <Box
        sx={{
          display: 'none',
          '@media (max-width: 600px)': {
            display: 'flex',
          },
        }}
      >
        {Lister(steps)}
      </Box>
    </Box>
  );
};
