import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import BarChartIcon from '@mui/icons-material/BarChart';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useTranslation } from 'react-i18next';
import { Lister } from '../lister/Lister';

export const OurServicesView = () => {
  const { t } = useTranslation();

  const SERVICES_LIST = [
    {
      title: t('services.engineering_and_management.title'),
      subtitle: t('services.engineering_and_management.subtitle'),
      description: t('services.engineering_and_management.description'),
      icon: <EngineeringIcon color={'primary'} fontSize="large" />,
      disposition: 'left',
    },
    {
      title: t('services.systems_integration.title'),
      subtitle: t('services.systems_integration.subtitle'),
      description: t('services.systems_integration.description'),
      icon: <JoinLeftIcon color={'primary'} fontSize="large" />,
      disposition: 'rigth',
    },
    {
      title: t('services.web_and_mobile.title'),
      subtitle: t('services.web_and_mobile.subtitle'),
      description: t('services.web_and_mobile.description'),
      icon: <LanguageSharpIcon color={'primary'} fontSize="large" />,
      disposition: 'left',
    },
    {
      title: t('services.data_and_ai.title'),
      subtitle: t('services.data_and_ai.subtitle'),
      description: t('services.data_and_ai.description'),
      icon: <BarChartIcon color={'primary'} fontSize="large" />,
      disposition: 'rigth',
    },
  ];

  return Lister(SERVICES_LIST);
};
