import { Box, Container } from '@mui/material';
import { Introduction } from '../introduction/Introduction';
import { ContactFooterController } from '../contactFooter/ContactFooter.controller';
// import { Staff } from '../staff/Staff';
import { NavBarController } from '../navBar/NavBar.controller';
import { OurServicesView } from '../ourServices/Services';
import { HowWeWorkSteps } from '../howWeWork/HowWeWorkSteps';
import { SectionWrapper } from '../sectionWrapper/sectionWrapper.view';
import { SECTIONS } from '../../constants/sections.constants';
import { useTranslation } from 'react-i18next';
import { Industries } from '../industries/Industries';
import { WhoWeAre } from '../whoWeAre/WhoWeAre';

export const LandingView = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <NavBarController />
      <Container
        sx={{
          backgroundColor: '#fafafa',
          '@media (min-width: 1200px)': {
            maxWidth: '1200px',
            marging: 0,
            padding: 0,
          },
          '@media (max-width: 600px)': {
            marging: 0,
            padding: 0,
          },
        }}
      >
        <Introduction />
        <SectionWrapper
          title={t('sections.our_services')}
          subTitle={null}
          sectionId={SECTIONS.SERVICES.name}
          orientation={'left'}
        >
          <OurServicesView />
        </SectionWrapper>
        <SectionWrapper
          title={t('sections.industries')}
          subTitle={null}
          sectionId={SECTIONS.INDUSTRIES.name}
          orientation={'right'}
        >
          <Industries />
        </SectionWrapper>

        {/* <SectionWrapper
          title={t('sections.our_team')}
          subTitle={null}
          sectionId={SECTIONS.TEAM.name}
          orientation={'left'}
        >
          <Staff />
        </SectionWrapper> */}
        <SectionWrapper
          title={t(SECTIONS.HOW_WE_WORK.label)}
          subTitle={null}
          sectionId={SECTIONS.HOW_WE_WORK.name}
          orientation={'left'}
        >
          <HowWeWorkSteps />
        </SectionWrapper>
        <SectionWrapper
          title={t(SECTIONS.WHO_WE_ARE.label)}
          subTitle={null}
          sectionId={SECTIONS.WHO_WE_ARE.name}
          orientation={'right'}
        >
          <WhoWeAre />
        </SectionWrapper>
      </Container>
      <ContactFooterController />
    </Box>
  );
};
