import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Lister } from '../lister/Lister';

export const WhoWeAre = () => {
  const { t } = useTranslation();

  const ITEMS_LIST = [
    {
      title: t('who_we_are.vision.title'),
      description: t('who_we_are.vision.description'),
      icon: <></>,
      disposition: 'left',
    },
    {
      title: t('who_we_are.mission.title'),
      description: t('who_we_are.mission.description'),
      icon: <></>,
      disposition: 'right',
    },
    {
      title: t('who_we_are.objectives.title'),
      description: [
        {
          item: t('who_we_are.objectives.customer.title'),
          content: t('who_we_are.objectives.customer.description'),
        },
        {
          item: t('who_we_are.objectives.community.title'),
          content: t('who_we_are.objectives.community.description'),
        },
        {
          item: t('who_we_are.objectives.excellence.title'),
          content: t('who_we_are.objectives.excellence.description'),
        },
        {
          item: t('who_we_are.objectives.leadership.title'),
          content: t('who_we_are.objectives.leadership.description'),
        },
        {
          item: t('who_we_are.objectives.growth.title'),
          content: t('who_we_are.objectives.growth.description'),
        },
      ],
      icon: <></>,
      disposition: 'left',
    },
  ];
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          padding: 3,
          backgroundColor: '#ffffff',
          '@media (max-width: 600px)': {
            padding: 0,
          },
        }}
      >
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" color={'primary'} fontWeight={600}>
            {t('who_we_are.main')}
          </Typography>
        </Box>
      </Box>
      <Box>{Lister(ITEMS_LIST)}</Box>
    </Box>
  );
};
