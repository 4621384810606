import axios, { AxiosResponse } from "axios";

import { ENVARS } from "../../config/envs";

console.log("ENVARS.ENVIROMENT", ENVARS.ENVIROMENT);
const baseURL =
  ENVARS.ENVIROMENT === "production"
    ? ENVARS.API_DEFAULT_URL_CLOUD
    : ENVARS.API_DEFAULT_URL_LOCAL;

console.log("🚀 ~ file: emailing.services.ts:6 ~ baseURL:", baseURL);
axios.defaults.baseURL = baseURL ? baseURL : "http://localhost:3005";

/**
 * makes a request to the api for saving contact data and sending a default email a
 * request to contact message
 * @param emailBody
 * @returns {Promise<AxiosResponse<any>>}
 */
export const sendContactEmail = async (
  emailBody: any
): Promise<AxiosResponse<any>> => {
  try {
    const response = await axios.post("/contact_request", emailBody);
    console.log(
      "🚀 ~ file: emailing.services.ts:13 ~ response.data:",
      response.data
    );
    return response;
  } catch (error) {
    console.log("🚀 ~ file: emailing.services.ts:31 ~ error:", error);

    throw error;
  }
};
