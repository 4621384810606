import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

interface ISectionWrapperProps {
  title: string;
  subTitle?: string | null;
  sectionId?: string | null;
  orientation?: string | null;
  children: React.ReactNode;
}

const renderSubtitle = (subTitle: string | null | undefined) => {
  return (
    subTitle && (
      <Box>
        <Typography component="p" variant="h5">
          {subTitle}
        </Typography>
      </Box>
    )
  );
};

const getOrientation = (direction: string | null | undefined) => {
  let finalDirection: string = 'right';
  if (direction) {
    finalDirection = direction;
  }
  return finalDirection;
};

export const SectionWrapper = ({
  title,
  subTitle,
  children,
  sectionId,
  orientation,
}: ISectionWrapperProps) => {
  const [isTitleInViewport, setIsTitleInViewport] = useState(false);
  const finalOrientation = getOrientation(orientation);
  const backGroundFade = `linear-gradient(to ${finalOrientation}, #8db4f2, #0340a1)100%`;

  const titleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsTitleInViewport(true);
          } else {
            setIsTitleInViewport(false);
          }
        });
      },
      { root: null, rootMargin: '0px', threshold: 0.5 }, // Adjust threshold as needed
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isTitleInViewport) {
      // Trigger the animation when the title is in the viewport
      // @ts-ignore
      titleRef.current.style.animation = 'slideInRight 2s ease-in';
    } else {
      // Reset the animation when the title is out of the viewport
      // @ts-ignore
      titleRef.current.style.animation = 'none';
    }
  }, [isTitleInViewport]);

  return (
    <>
      <Box
        id={sectionId ? sectionId : `section-${title}-container`}
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            background: backGroundFade,
            backgroundColor: '#0340a1',
            minHeight: '10vh',
          }}
        >
          <Typography
            ref={titleRef}
            variant="h3"
            color={'secondary'}
            sx={{
              textAlign: finalOrientation === 'right' ? 'right' : 'left',
              padding: '0 60px 0 60px',
              '@media (max-width: 600px)': {
                textAlign: 'center',
              },
              '@keyframes slideInRight': {
                from: {
                  transform: 'translateX(-30px)',
                },
                to: {
                  transform: 'translateX(0px)',
                },
              },
            }}
          >
            {title}
          </Typography>
          {renderSubtitle(subTitle)}
        </Box>
        <Box
          sx={{
            backgroundColor: 'whitesmoke',
          }}
        >
          {children}
        </Box>
        <Box
          sx={{
            minHeight: '2.5vh',
            background: backGroundFade,
            backgroundColor: '#0340a1',
            marginBottom: '5vh',
          }}
        ></Box>
      </Box>
    </>
  );
};
