import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0340a1",
    },
    secondary: {
      // main: "#ffffff",
      main: "#ffffff",
    },
    text: {
      secondary: "#ffffff",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {},
    },
    MuiOutlinedInput: {
      styleOverrides: {
        // colorSecondary: {
        //   color: "#ffffff",
        // },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        colorSecondary: {
          color: "#ffffff",
          fontColor: "#ffffff",
        },
        input: {
          color: "#ffffff",
        },
      },
    },
  },
});
