export const SECTIONS = Object.freeze({
  SERVICES: {
    name: 'services-ref',
    label: 'navbar.services',
  },
  TEAM: {
    name: 'team-ref',
    label: 'navbar.team',
  },
  INDUSTRIES: {
    name: 'industries-ref',
    label: 'navbar.industries',
  },
  HOW_WE_WORK: {
    name: 'how_we_work-ref',
    label: 'navbar.how_we_work',
  },
  WHO_WE_ARE: {
    name: 'who_we_are-ref',
    label: 'navbar.who_we_are',
  },
  CONTACT: {
    name: 'contact-ref',
    label: 'navbar.contact',
  },
});

export const SECTIONS_NAMES = Object.freeze({
  SERVICES: 'services-ref',
  TEAM: 'team-ref',
  INDUSTRIES: 'industries-ref',
  PORTFOLIO: 'porfolio-ref',
  HOW_WE_WORK: 'how_we_work-ref',
  CONTACT: 'contact-ref',
});
