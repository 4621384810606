import { Box, Card, CardContent, Typography } from '@mui/material';

export interface ListerBaseI {
  title: string;
  description: string;
}
export interface ListerI {
  title: string;
  subtitle?: string;
  description: string | { item?: string; content: string }[];
  icon: React.ReactNode;
  disposition: string;
}

const cardTitleStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: 1,
  width: '80%',
  '@media (max-width: 600px)': {
    width: 'auto',
  },
};

export const Lister = (elementsList: ListerI[]) => {
  return (
    <Box
      sx={{
        padding: 3,
        '@media (max-width: 600px)': {
          padding: 0,
          margin: 0,
        },
      }}
    >
      <>
        {elementsList.map((element) => (
          <Card
            color="secondary"
            id={`lister-card-${element.title}`}
            sx={{
              margin: 3,
              '@media (max-width: 600px)': {
                marging: 0,
              },
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection:
                  element.disposition === 'left' ? 'row' : 'row-reverse',
                justifyContent: 'space-evenly',
                '@media (max-width: 600px)': {
                  flexDirection: 'column',
                },
              }}
            >
              <Box
                sx={{
                  ...cardTitleStyle,
                  width: '40%',
                  display: 'flex',
                  flexDirection:
                    element.disposition === 'left' ? 'row' : 'row-reverse',
                  justifyContent: 'flex-start',
                  textAlign: element.disposition,
                  '@media (max-width: 600px)': {
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'left',
                    width: '100%',
                  },
                }}
              >
                {element.icon}
                <Box
                  sx={{
                    margin: 2,
                    padding: 1,
                  }}
                >
                  <Typography
                    variant="h4"
                    color={'primary'}
                    sx={{
                      textAlign: element.disposition,
                      '@media (max-width: 600px)': {
                        textAlign: 'center',
                      },
                    }}
                  >
                    {element.title}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: '80%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '@media (max-width: 600px)': {
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    width: '100%',
                  },
                }}
              >
                {element.subtitle ? (
                  <Box
                    id={element.subtitle}
                    sx={{
                      margin: '10px',
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      {element.subtitle}
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}

                <Box
                  sx={{
                    maxWidth: '80%',
                    '@media (max-width: 600px)': {
                      maxWidth: '100%',
                    },
                  }}
                >
                  {typeof element.description === 'string' ? (
                    <Typography
                      sx={{
                        fontWeight: 550,
                      }}
                    >
                      {element.description}
                    </Typography>
                  ) : (
                    <>
                      {element.description.map((e) => (
                        <Box
                          sx={{
                            padding: 2,
                          }}
                        >
                          {e.item ? (
                            <Typography
                              sx={{ fontWeight: 'bold' }}
                              variant="h6"
                            >
                              {e.item}
                            </Typography>
                          ) : (
                            <></>
                          )}
                          <Typography
                            sx={{
                              fontWeight: 550,
                            }}
                          >
                            {e.content}
                          </Typography>
                        </Box>
                      ))}
                    </>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        ))}
      </>
    </Box>
  );
};
