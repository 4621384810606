import { useTranslation } from 'react-i18next';
import { Lister } from '../lister/Lister';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import BoltIcon from '@mui/icons-material/Bolt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactoryIcon from '@mui/icons-material/Factory';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

export const Industries = () => {
  const { t } = useTranslation();
  const INDUSTRIES_LIST = [
    {
      title: t('industries.healthcare.title'),
      description: t('industries.healthcare.description'),
      icon: <LocalHospitalIcon color={'primary'} fontSize="large" />,
      disposition: 'left',
    },
    {
      title: t('industries.energy.title'),
      description: t('industries.energy.description'),
      icon: <BoltIcon color={'primary'} fontSize="large" />,
      disposition: 'rigth',
    },
    {
      title: t('industries.logistic.title'),
      description: t('industries.logistic.description'),
      icon: <LocalShippingIcon color={'primary'} fontSize="large" />,
      disposition: 'left',
    },
    {
      title: t('industries.production.title'),
      description: t('industries.production.description'),
      icon: <FactoryIcon color={'primary'} fontSize="large" />,
      disposition: 'rigth',
    },
    {
      title: t('industries.e-commerce.title'),
      description: t('industries.e-commerce.description'),
      icon: <AddShoppingCartIcon color={'primary'} fontSize="large" />,
      disposition: 'left',
    },
  ];
  return Lister(INDUSTRIES_LIST);
};
