import { useEffect, useState } from "react";
import { IContactFooterState } from "./ContactFooter.interface";
import { ContactFooterView } from "./ContactFooter.view";
import { sendContactEmail } from "../../services/emailing/emailing.services";

const initialState: IContactFooterState = {
  first_name: "",
  last_name: "",
  email: "",
  comments: "",
  phone: "",
};

export const ContactFooterController = () => {
  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    console.log(formState);
  }, [formState]);

  const handleChange = (field: string, value: string) => {
    setFormState({
      ...formState,
      [field]: value,
    });
  };

  const hanldleSubmit = async () => {
    try {
      console.log("Attempting to send email with data: ", formState);
      await sendContactEmail(formState);
    } catch (error) {
      alert("something wnet wrong");
    }
  };

  return (
    <ContactFooterView
      formState={formState}
      handleChange={handleChange}
      hanldleSubmit={hanldleSubmit}
    />
  );
};
