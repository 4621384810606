import { Box, Button, TextField, Typography } from '@mui/material';
import { IContactFooterViewProps } from './ContactFooter.interface';
import { SECTIONS } from '../../constants/sections.constants';
import { useTranslation } from 'react-i18next';

const inputContainersStyles = {
  margin: 1,
  padding: 1,
};

const flexCenteredStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const ContactFooterView = ({
  formState,
  handleChange,
  hanldleSubmit,
}: IContactFooterViewProps) => {
  const { t } = useTranslation();
  return (
    <Box
      id={SECTIONS.CONTACT.name}
      sx={{
        backgroundColor: '#0340a1',
        ...flexCenteredStyle,
        width: '100vw',
        background: 'linear-gradient(to right, #0340a1, #8db4f2)100%',
      }}
    >
      <Box
        sx={{
          width: '60vw',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          color: 'white',
          '@media (max-width: 600px)': {
            width: '100%',
          },
        }}
      >
        <Box
          sx={{
            ...flexCenteredStyle,
            marginTop: '2vh',
            marginBottom: '2vh',
          }}
        >
          <Typography variant="h5">{t('contact.contact_us')}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            color: 'inherit',
            padding: 2,
          }}
        >
          <Box
            sx={{
              ...inputContainersStyles,
              display: 'flex',
              flexDirection: 'row',
              '@media (max-width: 600px)': {
                flexDirection: 'column',
              },
            }}
          >
            <TextField
              label={t('contact.firstname')}
              value={formState.first_name}
              required={true}
              variant="standard"
              onChange={(event) =>
                handleChange('first_name', event.target.value)
              }
              fullWidth
              color={'secondary'}
              sx={{
                marginLeft: '1vw',
                marginRight: '1vw',
              }}
            />
            <TextField
              label={t('contact.lastname')}
              value={formState.last_name}
              required={true}
              sx={{
                marginLeft: '1vw',
                marginRight: '1vw',
              }}
              variant="standard"
              color={'secondary'}
              onChange={(event) =>
                handleChange('last_name', event.target.value)
              }
              fullWidth
            />
          </Box>
          <Box
            sx={{
              ...inputContainersStyles,
              display: 'flex',
              flexDirection: 'row',
              '@media (max-width: 600px)': {
                flexDirection: 'column',
              },
            }}
          >
            <TextField
              label="Email"
              value={formState.email}
              required={true}
              variant="standard"
              onChange={(event) => handleChange('email', event.target.value)}
              fullWidth
              color={'secondary'}
              sx={{
                marginLeft: '1vw',
                marginRight: '1vw',
              }}
            />
            <TextField
              label={t('contact.phone')}
              value={formState.phone}
              sx={{
                marginLeft: '1vw',
                marginRight: '1vw',
              }}
              variant="standard"
              color={'secondary'}
              onChange={(event) => handleChange('phone', event.target.value)}
              fullWidth
            />
          </Box>
          <Box
            sx={{
              margin: 2,
              padding: 2,
            }}
          >
            <TextField
              label={t('contact.comment')}
              value={formState.comments}
              variant="outlined"
              color={'secondary'}
              multiline={true}
              onChange={(event) => handleChange('comments', event.target.value)}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          sx={{
            ...inputContainersStyles,
            ...flexCenteredStyle,
            margin: '0 0 15px 0',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'space-between',
            '@media (max-width: 600px)': {
              flexDirection: 'column-reverse',
              alignItems: 'center',
              margin: '0 0 0 0',
              padding: 0,
            },
          }}
        >
          <Button
            variant="contained"
            color={'primary'}
            type="submit"
            onClick={() => hanldleSubmit()}
          >
            <Typography>{t('contact.send')}</Typography>{' '}
          </Button>
        </Box>
        <Box
          id="contact-info-container"
          sx={{
            ...flexCenteredStyle,
            justifyContent: 'space-around',
            '@media (max-width: 600px)': {
              flexDirection: 'column',
              height: '10vh',
              alignItems: 'center',
              textAlign: 'center',
            },
          }}
        >
          <Box>
            <Typography>Tafi Viejo | Tucuman | Argentina | 4106</Typography>
          </Box>
          <Box>
            <Typography>+54 0381 156719388</Typography>
          </Box>
          <Box>
            <Typography>info@noDomainYet.com</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
