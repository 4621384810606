import { useState } from 'react';
import { Box, Breadcrumbs, IconButton, Typography } from '@mui/material';
import './NavBar.styles.css';
import { SECTIONS } from '../../constants/sections.constants';
import { Link } from 'react-scroll';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import arFlag from '../../assets/flags/ar_flag.png';
import usFlag from '../../assets/flags/us_flag.png';
import { useTranslation } from 'react-i18next';
import backGroundTexture from '../../assets/backgrounds/background-texture.jpg';

interface NavBarViewPropsI {
  handleOpenMenu: Function;
  isMenuOpen: boolean;
}

const NavBarView = ({ handleOpenMenu, isMenuOpen }: NavBarViewPropsI) => {
  const { i18n, t } = useTranslation();

  const [language, setLanguage] = useState('es-ES');
  const [openSelector, setOpenSelector] = useState(false);

  const languageOptions = {
    'es-ES': {
      label: 'Español',
      icon: arFlag,
    },
    'en-US': {
      label: 'English',
      icon: usFlag,
    },
  };

  const sections = [
    {
      label: t(SECTIONS.SERVICES.label),
      name: SECTIONS.SERVICES.name,
    },
    {
      label: t(SECTIONS.INDUSTRIES.label),
      name: SECTIONS.INDUSTRIES.name,
    },
    {
      label: t(SECTIONS.HOW_WE_WORK.label),
      name: SECTIONS.HOW_WE_WORK.name,
    },
    {
      label: t(SECTIONS.WHO_WE_ARE.label),
      name: SECTIONS.WHO_WE_ARE.name,
    },
    {
      label: t(SECTIONS.CONTACT.label),
      name: SECTIONS.CONTACT.name,
    },
  ];

  const handleChangeLanguage = (ln: string) => {
    i18n.changeLanguage(ln);
    setLanguage(ln);
    setOpenSelector(false);
  };

  const selectedLanguageOption =
    languageOptions[language as keyof typeof languageOptions];

  const renderSections = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '116px',
          '@media (max-width: 600px)': {
            flexDirection: 'column',
            height: 'auto',
            padding: '12px',
            alignItems: 'center',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '750px',
            '@media (max-width: 600px)': {
              flexDirection: 'column',
              width: 'auto',
            },
          }}
        >
          {sections.map((section) => {
            return (
              <Box
                sx={{
                  margin: 1,
                  padding: 1,
                  '@media (max-width: 600px)': {
                    margin: 0,
                    padding: 0,
                  },
                }}
              >
                <Breadcrumbs>
                  <Link
                    to={section.name}
                    spy={true}
                    offset={-250}
                    duration={500}
                    smooth={true}
                    isDynamic={true}
                    onClick={() => handleOpenMenu()}
                  >
                    <Typography
                      variant={'h6'}
                      color={'secondary'}
                      sx={{
                        cursor: 'pointer',
                        '@media (max-width: 600px)': {
                          textAlign: 'right',
                        },
                        textDecoration: 'none', // No underline by default
                        '&:hover': {
                          textDecoration: 'underline', // Add underline on hover
                        },
                      }}
                    >
                      {section.label}
                    </Typography>
                  </Link>
                </Breadcrumbs>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 0 0 60px',
            width: '200px',
            '@media (max-width: 600px)': {
              margin: 0,
            },
          }}
        >
          {openSelector ? (
            <>
              {Object.keys(languageOptions).map((ln) => (
                <IconButton
                  onClick={(event) =>
                    handleChangeLanguage(event.currentTarget.value)
                  }
                  value={ln}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={
                        languageOptions[ln as keyof typeof languageOptions].icon
                      }
                      alt={
                        languageOptions[ln as keyof typeof languageOptions]
                          .label
                      }
                      width="20px"
                      height="15px"
                    />
                    <Typography
                      color="secondary"
                      sx={{
                        fontWeight: 600,
                        padding: '3px 10px 0 10px',
                      }}
                    >
                      {
                        languageOptions[ln as keyof typeof languageOptions]
                          .label
                      }
                    </Typography>
                  </Box>
                </IconButton>
              ))}
            </>
          ) : (
            <>
              <IconButton onClick={() => setOpenSelector(true)}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={selectedLanguageOption.icon}
                    alt={selectedLanguageOption.label}
                    width="20px"
                    height="15px"
                  />
                  <Typography
                    color="secondary"
                    sx={{
                      fontWeight: 600,
                      padding: '3px 10px 0 10px',
                    }}
                  >
                    {selectedLanguageOption.label}
                  </Typography>
                </Box>
              </IconButton>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const renderMobileSections = () => {
    return (
      <Box
        sx={{
          display: 'none',
          '@media (max-width: 1000px)': {
            display: 'flex',
            justifyContent: 'space-evenly',
          },
        }}
      >
        {!isMenuOpen && (
          <Box
            sx={{
              marginLeft: '0',
            }}
          >
            <IconButton onClick={() => handleOpenMenu()}>
              <MenuOutlinedIcon color="secondary" fontSize="large" />
            </IconButton>
          </Box>
        )}
        {isMenuOpen && (
          <Box
            sx={{
              padding: 3,
            }}
          >
            {renderSections()}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        background: 'linear-gradient(to left, #8db4f2, #0340a1)100%',
        backgroundImage: `url(${backGroundTexture})`,
        backgroundBlendMode: 'multiply',
        backgroundColor: '#0340a1',
        position: 'fixed',
        width: '100vw',
        zIndex: 998,
        height: '116px',
        '@media (max-width: 600px)': {
          height: 'auto',
        },
      }}
    >
      <IconButton>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80px',
            width: '250px',
            padding: '10px',
            '@media (max-width: 600px)': {
              height: '80px',
              width: '120px',
            },
          }}
        >
          <Link
            to={'introduction-ref'}
            spy={true}
            offset={-120}
            duration={500}
            smooth={true}
            onClick={() => isMenuOpen && handleOpenMenu()}
          >
            <Typography
              variant={'h4'}
              color={'secondary'}
              sx={{
                cursor: 'pointer',
              }}
            >
              ITafi
            </Typography>
          </Link>
        </Box>
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          '@media (max-width: 1000px)': {
            display: 'none',
          },
        }}
      >
        {renderSections()}
      </Box>
      {renderMobileSections()}
    </Box>
  );
};

export default NavBarView;
